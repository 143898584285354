











































import { defineComponent, computed, onMounted, onUnmounted } from '@vue/composition-api'
import DataTable from '@/elements/DataTable/DataTable.vue'
import { DataTableHeader } from 'vuetify/types'
import store from '@/store'
const normalizePhone = (phone: string) => {
  if (phone.includes('(')) return phone
  var cleaned = phone.replace('+1', '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)!
  return '(' + match[1] + ') ' + match[2] + '-' + match[3]
}
const bannedCodes = ['21211', '21610', '30005', '30003', '30006']
enum Actions {
  Banned = 'This number will not receive future messages.',
  None = 'None taken.',
}
export default defineComponent({
  name: 'Failures',
  props: {},
  components: {
    DataTable,
  },
  data() {
    const headers = ([
      { text: 'Message', value: 'body', width: '60%', sortable: false },
      { text: 'Failures', value: 'failures.length', align: 'center' },
      { text: '', value: 'data-table-expand', sortable: false },
      { text: 'Sent', value: 'sentDate', sortable: false },
    ] as unknown) as DataTableHeader

    return { headers }
  },
  setup() {
    const statuses = computed(() => {
      const groups = {} as any
      store.getters.communications.statuses.forEach((s) => {
        const dateStrUtc = s.createdOnUtc ?? s.lastUpdateUtc
        const date = dateStrUtc ? new Date(dateStrUtc) : null
        const formattedDateStr = date ? `${date.toLocaleString()}` : null
        const group = groups[s.communicationID] ?? {
          id: s.id,
          body: s.body,
          sentDate: formattedDateStr,
          failures: [],
        }
        group.failures.push(s)
        groups[s.communicationID] = group
      })
      return Object.keys(groups).map((k) => {
        return { ...groups[k] }
      })
    })
    const partnerInfo = computed(() => store.getters.communications.currentItem)
    const banned = (errorCode: string | null) => {
      return bannedCodes.includes(errorCode!)
    }
    const getAction = (errorCode: string | null) => {
      return banned(errorCode) ? Actions.Banned : Actions.None
    }
    onMounted(() =>
      store.dispatch.communications.retrieveStatuses({
        accountNumber: partnerInfo.value.accountNumber ?? '',
        upwardLeagueID: partnerInfo.value.upwardLeagueID ?? '',
      })
    )
    onUnmounted(() => {
      store.commit.communications.clearStatuses()
    })
    return { statuses, getAction, normalizePhone, banned }
  },
})
